import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from '..';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Gatsby Themes`}</h1>
    <p>{`A work-in-progress exploration of Gatsby themes`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/jxnblk/gatsby-themes"
      }}>{`https://github.com/jxnblk/gatsby-themes`}</a></p>
    <h2>{`Examples`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/theme-ui-blog"
        }}>{`Theme UI Blog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/tomato-blog"
        }}>{`Tomato`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/swiss-blog"
        }}>{`Swiss`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/mono-blog"
        }}>{`Mono`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/mono-notes"
        }}>{`Mono Notes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/jxnblk-blog"
        }}>{`Jxnblk`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/css"
        }}>{`CSS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/system"
        }}>{`System`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/conference-styled"
        }}>{`Conference`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      